import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { InertiaApp } from "@inertiajs/inertia-react";
import LoadingSpinner from "./components/LoadingSpinner.js";
//Bootstrap
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

import "../css/app.css";
import "../css/style.css";

const routeRedirect = (name) => {
    const [section, page] = name.split("/");
    return lazy(() => import(`./Pages/${section}/${page}`));
};

ReactDOM.createRoot(document.getElementById("app")).render(
    <Suspense fallback={<LoadingSpinner />}>
        <InertiaApp
            initialPage={JSON.parse(app.dataset.page)}
            resolveComponent={routeRedirect}
        />
    </Suspense>
);
